<template>
  <div class="carrier_box">
    <div class="top_box">
      <div class="topOperation_content" @click="addEvent">
        <img class="topFunction_img" src="https://hyzg-app.oss-cn-beijing.aliyuncs.com/hanDuck/xinzeng.png" alt="">
        <div>新增</div>
      </div>

      <div class="topOperation_content" @click="editEvent">
        <img class="topFunction_img" src="https://hyzg-app.oss-cn-beijing.aliyuncs.com/hanDuck/bianji.png" alt="">
        <div>编辑</div>
      </div>
    </div>

    <div class="filter_Feature_Style">
      <div class="filter_Feature_items">
        <div class="filter_Feature_content">
          <div class="screen_title">企业名称：</div>
          <el-select class="screenInput" v-model="params.compName" filterable placeholder="请选择" size="mini" clearable>
            <el-option v-for="item in enterprise" :key="item.id" :label="item.compName" :value="item.compName"></el-option>
          </el-select>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">认证状态：</div>
          <el-select class="screenInput" v-model="params.status" placeholder="请选择" size="mini" clearable>
            <el-option label="未认证" :value="0"></el-option>
            <el-option label="已认证" :value="1"></el-option>
          </el-select>
        </div>
      </div>
      <div class="query_btn">
        <el-button icon="el-icon-search" type="button" size="mini" @click="query">查 询</el-button>
      </div>
    </div>

    <div class="table_box">
      <el-table :data="tableData" border style="width: 100%" :header-cell-style="{ 
      background:'#ebeef5',hight:'50px',fontSize:'12px'}" @selection-change="handleSelectionChange" v-loading="tableLoading" element-loading-text="数据正在加载中...">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column label="企业名称" width="250" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="details_style" @click="orderDetails(scope.row)">{{scope.row.compName}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="compContactUser" label="联系人" width="150" align="center"></el-table-column>
        <el-table-column prop="compContactPhone" label="联系电话" align="center" width="150"></el-table-column>
        <el-table-column prop="userCount" label="账号" width="120" align="center"></el-table-column>
        <el-table-column prop="compAddress" label="地址" min-width="300" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="taxNo" label="统一社会信用代码" width="180" align="center"></el-table-column>
        <el-table-column prop="legalRepresentative" label="企业法人" width="180" align="center"></el-table-column>
        <el-table-column prop="registeredDate" label="成立日期" width="180" align="center"></el-table-column>
        <el-table-column label="认证状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">未认证</span>
            <span v-if="scope.row.status == 1">已认证</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdDt" label="新建时间" align="center" width="180"></el-table-column>
        <el-table-column prop="updatedDt" label="编辑时间" align="center" width="180"></el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" small background :current-page="params.pageNum" :page-size="params.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { companyFind, findCompNames } from "@/api/basics";
import utils from "@/plugin/utils/utils";
export default {
  name:"/carrier/carrier",
  data() {
    return {
      tableData: [],
      params: {
        pageSize: 10,
        pageNum: 1,
      },
      total: 0,
      enterprise: [],
      multipleSelection: [],
      tableLoading: false,
    };
  },
  mounted() {
    this.getList();
    this.getEnterprise();
  },
  activated(){
    console.log('activated');
    this.getList();
  },
  methods: {
    query() {
      this.params.pageNum = 1;
      this.getList();
    },

    async getList() {
      try {
        this.tableLoading = true;
        let data = utils.removeNull(this.params);
        let res = await companyFind(data);
        if (res.retCode == "0000000") {
          this.tableLoading = false;
          this.tableData = res.rspBody.items;
          this.total = res.rspBody.total;
        } else {
          this.tableLoading = false;
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      } catch (error) {
        console.log(error);
        this.tableLoading = false;
        this.$message({
          type: "error",
          message: error.message,
        });
      }
    },

    orderDetails(row) {
      console.log(row,'详情');
      let list = JSON.stringify(row);
      this.$router.push({
        path: "/carrier/addCarrier",
         query:{
          row:list,
          type:'details'
        }
      });
    },

    async getEnterprise() {
      try {
        let res = await findCompNames({});
        console.log(res, "res");
        this.enterprise = res.rspBody.items;
      } catch (error) {
        console.log(error);
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getList();
    },

    addEvent() {
      this.$router.push({
        path: "/carrier/addCarrier",
         query:{
          type:'add'
        }
      });
    },

    editEvent() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          type: "warning",
          message: "请选择数据",
        });
        return;
      }
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "warning",
          message: "请选择一条数据",
        });
        return;
      }
      let list = JSON.stringify(this.multipleSelection[0])
      this.$router.push({
        path: "/carrier/addCarrier",
        query:{
          row:list,
          type:'edit'
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.carrier_box {
  width: 100%;
  height: 100%;

  .table_box {
    margin-top: 15px;
  }
 .details_style {
    color: #086ffd;
    cursor: pointer;
  }
  
}
</style>